// PdfExport.js

import html2pdf from "html2pdf.js";
import { message } from "antd";

export default function PdfExport(data) {
  // Function to handle exporting to PDF using a custom HTML template
  const handleExportPDF = () => {
    if (!data) return;

    // Create a temporary div to hold the PDF content
    const pdfContent = document.createElement("div");
    pdfContent.style.padding = "20px";
    pdfContent.style.fontFamily = "Arial, sans-serif";
    pdfContent.style.fontSize = "12px";

    // Build the HTML content
    pdfContent.innerHTML = `
      <!-- Your HTML content for PDF generation -->
      <h1>Indigent Application Form 2024/2025</h1>
      <p><strong>ID Number:</strong> ${data.idNumber}</p>
      <p><strong>Full Name:</strong> ${data.firstNames} ${data.surName}</p>
      <!-- Include other fields as needed -->
    `;

    // Use html2pdf.js to generate the PDF
    const options = {
      margin: [10, 10, 10, 10],
      filename: `${data.firstNames}_${data.surName}_Application.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    html2pdf()
      .set(options)
      .from(pdfContent)
      .save()
      .catch((err) => {
        console.error("Error generating PDF:", err);
        message.error("Failed to export PDF");
      });
  };

  handleExportPDF();
}