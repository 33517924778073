const BASE_URL = process.env.REACT_APP_API_URL;

export const API_CONFIG = {
  baseUrl: BASE_URL,
  verificationUrl: `${BASE_URL}showverifications`,
  saveUrl: `${BASE_URL}saveincome`,
  searchUrl: `${BASE_URL}registerperson`,
  loginUrl: `${BASE_URL}login`,
  changePasswordUrl: `${BASE_URL}changePassword`,
  apiKey: process.env.REACT_APP_VERSION,

  
};