// Popup.js

import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Collapse,
  Typography,
  Input,
  Checkbox,
  DatePicker,
  Select,
  Divider,
  Radio,
  Spin,
  Upload,
  message,
  Table,
} from "antd";
import SignatureCanvas from "react-signature-canvas";
import { API_CONFIG } from "./config";
import moment from "moment";
import {
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import PdfExport from "./PdfExport"; // Import the PDF export function

const { Text, Paragraph } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

export default function Popup({ visible, onClose, personData }) {
  const [editedData, setEditedData] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(true);
  const indigentSignaturePadRef = useRef(null);
  const officerSignaturePadRef = useRef(null);
  const [employmentDetails, setEmploymentDetails] = useState([]);
  const [contactDetails, setContactDetails] = useState([]);
  const [householdMembers, setHouseholdMembers] = useState([]);
  const [financialInfo, setFinancialInfo] = useState({});
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [existingDocuments, setExistingDocuments] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [indigentSignatureData, setIndigentSignatureData] = useState(null);
  const [officerSignatureData, setOfficerSignatureData] = useState(null);

  // Extract household members from the API data
  const fetchHouseholdMembers = (data) => {
    const householdMembers = [];
    for (let i = 1; i <= 5; i++) {
      const name = data[`HouseholdMember${i}_Name`];
      const surname = data[`HouseholdMember${i}_Surname`];
      const relationship = data[`HouseholdMember${i}_Relationship`];
      const idNumber = data[`HouseholdMember${i}_IDNumber`];
      const income = data[`HouseholdMember${i}_Income`];

      if (name || surname) {
        householdMembers.push({
          name: name || "",
          surname: surname || "",
          relationship: relationship || "",
          idNumber: idNumber || "",
          income: income || 0,
        });
      }
    }
    return householdMembers;
  };

  // Extract supporting documents from API data
  const extractSupportingDocuments = (data) => {
    const documents = [];
    for (let i = 1; i <= 5; i++) {
      const url = data[`Document${i}_URL`];
      const type = data[`Document${i}_Type`];
      if (url && type) {
        // Extract file name from URL
        const fileName = url.substring(url.lastIndexOf("/") + 1);
        documents.push({
          key: `existing-${i}`,
          documentType: type,
          fileName: fileName,
          url: url,
        });
      }
    }
    return documents;
  };

  useEffect(() => {
    if (personData) {
      // Initialize data
      setEditedData({ ...personData });
      // Extract supporting documents from personData
      const supportingDocuments = extractSupportingDocuments(personData);
      console.log(
        "Extracted documents:",
        extractSupportingDocuments(personData)
      );
      setExistingDocuments(supportingDocuments);
      // Extract household members
      setHouseholdMembers(fetchHouseholdMembers(personData));

      // Extract employment details
      const extractEmploymentDetails = (data) => {
        const employmentDetails = [];
        for (let i = 1; i <= 5; i++) {
          const empName = data[`Employment${i}_EmpName`];
          const empPosition = data[`Employment${i}_EmpPosition`];

          if (empName || empPosition) {
            employmentDetails.push({
              EmpName: empName || "",
              EmpPosition: empPosition || "",
            });
          }
        }
        return employmentDetails.length > 0
          ? employmentDetails
          : [{ EmpName: "", EmpPosition: "" }];
      };
      setEmploymentDetails(extractEmploymentDetails(personData));

      // Extract contact details
      const extractContactDetails = (data) => {
        const contactDetails = [];
        for (let i = 1; i <= 5; i++) {
          const number = data[`Contact${i}_Number`];
          const typeDescription = data[`Contact${i}_TypeDescription`];

          if (number || typeDescription) {
            contactDetails.push({
              Number: number || "",
              TypeDescription: typeDescription || "",
            });
          }
        }
        return contactDetails.length > 0
          ? contactDetails
          : [{ Number: "", TypeDescription: "" }];
      };
      setContactDetails(extractContactDetails(personData));

      // Initialize financial info
      setFinancialInfo(personData.financialInfo || {});

      // Set terms accepted
      setTermsAccepted(personData.termsAccepted || false);

      // Set signatures
      setIndigentSignatureData(personData.indigentSignature || null);
      setOfficerSignatureData(personData.officerSignature || null);

      setIsModalLoading(false);
    }
  }, [personData]);

  const handleFieldChange = (key, value) => {
    setEditedData({ ...editedData, [key]: value });
  };

  const handleSaveData = async () => {
    if (!personData) {
      message.error("No person data found to save.");
      return;
    }

    setIsSaving(true);

    try {
      // Ensure terms and conditions are accepted
      if (!termsAccepted) {
        message.error("Please accept the terms and conditions before saving.");
        setIsSaving(false);
        return;
      }

      // Capture signatures
      const indigentSignature = indigentSignaturePadRef.current
        ? indigentSignaturePadRef.current
            .getTrimmedCanvas()
            .toDataURL("image/png")
        : null;
      const officerSignature = officerSignaturePadRef.current
        ? officerSignaturePadRef.current
            .getTrimmedCanvas()
            .toDataURL("image/png")
        : null;

      // Process uploaded documents
      const documentsData = uploadedDocuments.map((doc) => {
        const base64Prefix = doc.file.base64.split(",")[0];
        const mimeType = base64Prefix.match(/data:([^;]+);base64/)[1];

        const mimeToExtension = {
          "application/pdf": ".pdf",
          "image/png": ".png",
          "image/jpeg": ".jpg",
          "image/jpg": ".jpg",
          "text/plain": ".txt",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            ".docx",
        };

        const fileExtension = mimeToExtension[mimeType] || ".unknown";
        const fullFileName = `${doc.fileName}${fileExtension}`;

        return {
          fullFileName,
          documentType: doc.documentType,
          file: doc.file.base64.split(",")[1], // Extract Base64 content
        };
      });

      // Prepare payload with relevant data
      const payload = {
        ...editedData,
        employmentDetails,
        contactDetails,
        householdMembers,
        financialInfo,
        indigentSignature,
        officerSignature,
        supportingDocuments: documentsData,
        termsAccepted,
      };

      // API call to save data
      const response = await fetch(API_CONFIG.saveUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": API_CONFIG.apiKey,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to save data");
      }

      message.success("Data saved successfully");
      onClose(); // Close modal after successful save
    } catch (error) {
      console.error("Error saving data:", error);
      message.error("Error saving data. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  const handleExportPDF = () => {
    PdfExport(personData);
  };

  const handleClearSignature = (signaturePadRef) => {
    signaturePadRef.current.clear();
  };

  // Document Types
  const documentTypes = [
    "Municipal Account",
    "Id Copy",
    "Certified Birth Certificate",
    "Proof of Income",
    "Proof of SASA",
    "Income Affidavit",
    "Ward Councillor recommendation letter",
    "Owner Certified Death Certificate",
    "Proof of Total Gross Income",
    "Proof of Unemployment",
    "Court Order for Guardianship",
    "Proof of Vehicle ownership",
    "Copy of Prepaid Electricity Slip",
    "Proof of Other Properties",
    "Marriage Certificate",
    "SARS Consent Form",
    "Letter of Beneficiary",
  ];

  // Handle File Upload
  const handleFileChange = ({ file }) => {
    if (file.status === "done") {
      // Convert file to base64
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => {
        const base64 = reader.result;
        const newDocument = {
          key: `uploaded-${uploadedDocuments.length}`,
          file: { ...file, base64 },
          documentType: null, // To be selected
          fileName: file.name,
        };
        setUploadedDocuments([...uploadedDocuments, newDocument]);
      };
    }
  };

  // Handle Document Type Change
  const handleDocumentTypeChange = (index, value) => {
    const updatedDocuments = [...uploadedDocuments];
    updatedDocuments[index].documentType = value;
    setUploadedDocuments(updatedDocuments);
  };

  // Handle Remove Document
  const handleRemoveDocument = (index, isExisting = false) => {
    if (isExisting) {
      const updatedDocuments = [...existingDocuments];
      updatedDocuments.splice(index, 1);
      setExistingDocuments(updatedDocuments);
    } else {
      const updatedDocuments = [...uploadedDocuments];
      updatedDocuments.splice(index, 1);
      setUploadedDocuments(updatedDocuments);
    }
  };

  const existingDocumentColumns = [
    {
      title: "Document Type",
      dataIndex: "documentType",
      key: "documentType",
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <div>
          {record.url && (
            <Button type="link" href={record.url} target="_blank">
              View
            </Button>
          )}
          <Button
            type="link"
            onClick={() => handleRemoveDocument(index, true)}
            danger
          >
            Remove
          </Button>
        </div>
      ),
    },
  ];

  // Employment Details Handlers
  const handleEmploymentChange = (index, field, value) => {
    const updatedEmployment = [...employmentDetails];
    updatedEmployment[index][field] = value;
    setEmploymentDetails(updatedEmployment);
  };

  const addEmploymentEntry = () => {
    setEmploymentDetails([
      ...employmentDetails,
      { EmpName: "", EmpPosition: "" },
    ]);
  };

  const removeEmploymentEntry = (index) => {
    const updatedEmployment = [...employmentDetails];
    updatedEmployment.splice(index, 1);
    setEmploymentDetails(updatedEmployment);
  };

  // Contact Details Handlers
  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contactDetails];
    updatedContacts[index][field] = value;
    setContactDetails(updatedContacts);
  };

  const addContactEntry = () => {
    setContactDetails([...contactDetails, { Number: "", TypeDescription: "" }]);
  };

  const removeContactEntry = (index) => {
    const updatedContacts = [...contactDetails];
    updatedContacts.splice(index, 1);
    setContactDetails(updatedContacts);
  };

  // Household Members Handlers
  const handleHouseholdMemberChange = (index, field, value) => {
    const updatedMembers = [...householdMembers];
    updatedMembers[index][field] = value;
    setHouseholdMembers(updatedMembers);
  };

  const addHouseholdMember = () => {
    setHouseholdMembers([
      ...householdMembers,
      { name: "", surname: "", relationship: "", idNumber: "", income: "" },
    ]);
  };

  const removeHouseholdMember = (index) => {
    const updatedMembers = [...householdMembers];
    updatedMembers.splice(index, 1);
    setHouseholdMembers(updatedMembers);
  };

  // Financial Information Handlers
  const handleFinancialInfoChange = (incomeSource, person, value) => {
    const updatedFinancialInfo = { ...financialInfo };
    if (!updatedFinancialInfo[incomeSource]) {
      updatedFinancialInfo[incomeSource] = {};
    }
    updatedFinancialInfo[incomeSource][person] = Number(value) || 0;
    setFinancialInfo(updatedFinancialInfo);
  };

  const calculateTotalPerPerson = (person) => {
    let total = 0;
    Object.values(financialInfo).forEach((amounts) => {
      total += Number(amounts[person]) || 0;
    });
    return total;
  };

  const calculateTotalHouseholdIncome = () => {
    return (
      calculateTotalPerPerson("husband") +
      calculateTotalPerPerson("wife") +
      calculateTotalPerPerson("adultLivingWithParents") +
      calculateTotalPerPerson("other")
    );
  };

  const formatIncomeSource = (key) => {
    const mapping = {
      wage: "Wage",
      pensionEmployer: "Pension (Employer)",
      pensionDisability: "Pension (Disability)",
      pensionOldAge: "Pension (Old Age)",
      boardRental: "Board Rental",
      childSupport: "Child Support",
      informalWork: "Informal Work",
      otherIncome: "Other Income",
    };
    return mapping[key] || key;
  };

  // Function to calculate age from date of birth
  const calculateAge = (dob) => {
    if (!dob) return "N/A";
    const birthDate = new Date(dob);
    const ageDifMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  return (
    <Modal
      title="Indigent Application Form 2024/2025"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button
          key="export"
          onClick={handleExportPDF}
          style={{ marginRight: "auto", visibility: "hidden" }}
        >
          Export to PDF
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={handleSaveData}
          loading={isSaving}
          style={{ backgroundColor: "#28a745", borderColor: "#28a745" }}
        >
          Save
        </Button>,
      ]}
      width={800}
    >
      {isModalLoading ? (
        <div style={{ textAlign: "center", padding: "50px" }}>
          <Spin size="large" />
        </div>
      ) : (
        personData && (
          <div>
            <Collapse
              activeKey={expandedPanels}
              onChange={(keys) => setExpandedPanels(keys)}
            >
              {/* Section A - Account Information */}
              <Panel header="Section A - Account Information" key="accountInfo">
                {/* Account Name */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Account Name:</Text>
                  <Input
                    value={editedData.accountName || ""}
                    onChange={(e) =>
                      handleFieldChange("accountName", e.target.value)
                    }
                    placeholder="Enter Account Name"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>

                {/* Account Number */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Account Number:</Text>
                  <Input
                    value={editedData.accountNumber || ""}
                    onChange={(e) =>
                      handleFieldChange("accountNumber", e.target.value)
                    }
                    placeholder="Enter Account Number"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>

                {/* Erf No */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Erf No:</Text>
                  <Input
                    value={editedData.erfNumber || ""}
                    onChange={(e) =>
                      handleFieldChange("erfNumber", e.target.value)
                    }
                    placeholder="Enter Erf No"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>

                {/* Eskom Pre-paid Meter Number */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Eskom Pre-paid Meter Number:</Text>
                  <Input
                    value={editedData.prepaidMeterNumber || ""}
                    onChange={(e) =>
                      handleFieldChange("prepaidMeterNumber", e.target.value)
                    }
                    placeholder="Enter Eskom Pre-paid Meter Number"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>

                {/* Date of Application */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Date of Application:</Text>
                  <DatePicker
                    value={
                      editedData.applicationDate
                        ? moment(editedData.applicationDate)
                        : null
                    }
                    onChange={(date) =>
                      handleFieldChange(
                        "applicationDate",
                        date ? date.format("YYYY-MM-DD") : null
                      )
                    }
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>

                {/* Address */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Address:</Text>
                  <Input
                    value={editedData.accountAddress || ""}
                    onChange={(e) =>
                      handleFieldChange("accountAddress", e.target.value)
                    }
                    placeholder="Enter Address"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>

                {/* Ward Number */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Ward Number:</Text>
                  <Select
                    value={editedData.wardNumber || undefined}
                    onChange={(value) => handleFieldChange("wardNumber", value)}
                    placeholder="Select Ward Number"
                    style={{ width: "100%", marginTop: 8 }}
                  >
                    {Array.from({ length: 100 }, (_, i) => (
                      <Option key={i + 1} value={i + 1}>
                        {i + 1}
                      </Option>
                    ))}
                  </Select>
                </div>
              </Panel>

              {/* Section B - Terms and Conditions */}
              <Panel
                header="Section B - Terms and Conditions"
                key="termsAndConditions"
              >
                <div style={{ marginBottom: 16 }}>
                  <Paragraph>
                    • Only households where the total household income is less
                    or equal to R4 500.00 (Four thousand five hundred rand) per
                    household of Council’s policy may apply for support.
                  </Paragraph>
                  <Paragraph>
                    • External verification will be done using the ITC as well a
                    physical audit to confirm the household financial status.
                    Upon signing this application, the consumer gives consent to
                    such checks.
                  </Paragraph>
                  <Paragraph>
                    • False information or the withholding of information will
                    disqualify anyone from further participation in the subsidy
                    scheme, with the liability of immediate repayment of all
                    allocations received plus interest and the risk of possible
                    criminal proceedings being instituted.
                  </Paragraph>
                  <Paragraph>
                    • Should the gross income of a household increase and/or
                    they exceed the approved limit during the subsidy time
                    period (Increases, no longer unemployed), the registered
                    consumer must immediately inform the municipality. Failure
                    to comply could result in the repayment of all allocations
                    plus interest and the risk of possible legal steps being
                    instituted.
                  </Paragraph>
                  <Paragraph>
                    • No property may be sold by the indigent consumer within a
                    timeframe of 36 months, after any write offs have been done,
                    should this occur then the new owner will be held liable to
                    pay the amounts that have been written off.
                  </Paragraph>
                  <Paragraph>
                    • The information contained in this document is not
                    confidential. A list of approved applicants can be handed to
                    councillors for comments, as well as publicly displayed.
                  </Paragraph>
                  <Paragraph>
                    • The City of Matlosana reserves the right to collect any
                    outstanding debts on the account by means of the pre-paid
                    services, in a portion not excessive in relation to the
                    purchases made by the account holder.
                  </Paragraph>
                  <Paragraph>
                    • That City of Matlosana may issue my Free Basic Electricity
                    (50 units) via my cell phone.
                  </Paragraph>
                  <Paragraph>
                    • Consent to the South African Revenue Service (SARS) in
                    terms of section 69 (6) (b) of the Tax Administration Act NO
                    28 of 2011 (TAA)
                  </Paragraph>
                  <Paragraph>
                    I, hereby declare that I am the registered owner/lessee of
                    the above erf/stand and that the said property is inhabited
                    and controlled by me. I further declare that I fully realize
                    that should any of the above information be found to be
                    incorrect or false, I shall be responsible for the repayment
                    of any allocation received plus interest, as well as any
                    debt written off, and I acknowledge that legal steps for the
                    fraudulent declaration could be instituted against me. I
                    confirm that I have read, understood, and accepted the terms
                    and conditions stated above. I also give my permission that
                    a credit check may be done on my details to determine the
                    validity of my information and that my indigent status may
                    be listed at an ITC institution. I/we the undersigned
                    Applicant(s), hereby give consent to SARS to disclose my/our
                    information to the Municipality [name of municipality] and
                    the National Department of Cooperative Governance (COGTA)
                    for purposes of verifying the details of my/our income
                    levels that I/we have disclosed to the Municipality in
                    support of my/our application for a municipal indigent
                    grant.
                  </Paragraph>
                  <Paragraph>
                    I/We, the undersigned Applicant(s), hereby give consent to
                    SARS to disclose my/our information to the Matlosana
                    Municipality and the National Department of Cooperative
                    Governance (COGTA) for the purposes of verifying the details
                    of my/our income levels that I/we have disclosed to the
                    municipality in support of my/our application for a
                    municipal indigent grant.
                  </Paragraph>
                  {/* Include the terms and conditions text */}
                  <Paragraph>
                    <Text strong>Terms and Conditions:</Text>
                    <ol>
                      <li>
                        I understand that the information provided in this
                        application will be verified by the municipality.
                      </li>
                      <li>
                        I agree to notify the municipality of any changes in my
                        financial situation.
                      </li>
                      <li>
                        I acknowledge that providing false information may lead
                        to legal action.
                      </li>
                      <li>
                        I consent to the municipality accessing my personal data
                        for the purpose of this application.
                      </li>
                      <li>
                        I understand that approval is subject to the
                        municipality's discretion and policies.
                      </li>
                      {/* Add more terms as required */}
                    </ol>
                  </Paragraph>
                </div>
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                >
                  I have read and accept the terms and conditions.
                </Checkbox>
              </Panel>

              {/* Personal Information Section */}
              <Panel header="Personal Information" key="personalInfo">
                <div style={{ marginBottom: 8 }}>
                  <Text strong>ID Number:</Text> {personData.idNumber}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Full Name:</Text> {personData.firstNames}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Age:</Text> {calculateAge(personData.dob)}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Date of Birth:</Text>{" "}
                  {personData.dob
                    ? new Date(personData.dob).toLocaleDateString()
                    : "N/A"}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Gender:</Text> {personData.gender}
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Deceased Status:</Text>
                  <span
                    style={{
                      color:
                        personData.deceasedStatus === "Alive" ? "green" : "red",
                    }}
                  >
                    {personData.deceasedStatus}
                  </span>
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Marital Status:</Text>
                  <span style={{ color: "green" }}>
                    {personData.maritalStatus}
                  </span>
                </div>
              </Panel>

              {/* Section C - Individuals Residing with the Applicant */}
              <Panel
                header="Section C - Individuals Residing with the Applicant"
                key="householdMembers"
              >
                {householdMembers.map((member, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: 16,
                      borderBottom: "1px solid #f0f0f0",
                      paddingBottom: 16,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text strong>Household Member {index + 1}:</Text>
                      <Button
                        type="danger"
                        icon={<MinusCircleOutlined />}
                        onClick={() => removeHouseholdMember(index)}
                      >
                        Remove
                      </Button>
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Name:</Text>
                      <Input
                        value={member.name}
                        onChange={(e) =>
                          handleHouseholdMemberChange(
                            index,
                            "name",
                            e.target.value
                          )
                        }
                        placeholder="Enter Name"
                        style={{ width: "100%", marginTop: 8 }}
                      />
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Surname:</Text>
                      <Input
                        value={member.surname}
                        onChange={(e) =>
                          handleHouseholdMemberChange(
                            index,
                            "surname",
                            e.target.value
                          )
                        }
                        placeholder="Enter Surname"
                        style={{ width: "100%", marginTop: 8 }}
                      />
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Relationship:</Text>
                      <Input
                        value={member.relationship}
                        onChange={(e) =>
                          handleHouseholdMemberChange(
                            index,
                            "relationship",
                            e.target.value
                          )
                        }
                        placeholder="Enter Relationship"
                        style={{ width: "100%", marginTop: 8 }}
                      />
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>ID Number:</Text>
                      <Input
                        value={member.idNumber}
                        onChange={(e) =>
                          handleHouseholdMemberChange(
                            index,
                            "idNumber",
                            e.target.value
                          )
                        }
                        placeholder="Enter ID Number"
                        style={{ width: "100%", marginTop: 8 }}
                      />
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Income:</Text>
                      <Input
                        value={member.income}
                        onChange={(e) =>
                          handleHouseholdMemberChange(
                            index,
                            "income",
                            e.target.value
                          )
                        }
                        type="number"
                        placeholder="Enter Income"
                        style={{ width: "100%", marginTop: 8 }}
                      />
                    </div>
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={addHouseholdMember}
                  style={{ width: "100%" }}
                >
                  <PlusOutlined /> Add Household Member
                </Button>
              </Panel>

              {/* Section D - Financial Information */}
              <Panel
                header="Section D - Financial Information"
                key="financialInfo"
              >
                {/* Financial Information Table */}
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        Income Source
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        Husband
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        Wife
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        Adult Living with Parents
                      </th>
                      <th
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                        Other
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      "wage",
                      "pensionEmployer",
                      "pensionDisability",
                      "pensionOldAge",
                      "boardRental",
                      "childSupport",
                      "informalWork",
                      "otherIncome",
                    ].map((incomeSource) => (
                      <tr key={incomeSource}>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {formatIncomeSource(incomeSource)}
                        </td>
                        {[
                          "husband",
                          "wife",
                          "adultLivingWithParents",
                          "other",
                        ].map((person) => (
                          <td
                            key={person}
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                            }}
                          >
                            <Input
                              value={
                                financialInfo[incomeSource]?.[person] || ""
                              }
                              onChange={(e) =>
                                handleFinancialInfoChange(
                                  incomeSource,
                                  person,
                                  e.target.value
                                )
                              }
                              type="number"
                              placeholder="0.00"
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Total Income */}
                <div style={{ marginTop: 16 }}>
                  <Text strong>Total Household Income:</Text>{" "}
                  {calculateTotalHouseholdIncome().toFixed(2)}
                </div>
              </Panel>

              <Panel
                header="Section E - Checklist (Office Use Only)"
                key="checklist"
              >
                <div>
                  {/* Checklist Items */}
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginBottom: "16px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "left",
                          }}
                        >
                          Description
                        </th>
                        <th
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          Please Tick
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          ID document - Documentary proof of total gross income
                          of household account holder, spouse, and persons older
                          than 18 years residing on premises.
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Checkbox
                            checked={editedData.checklist?.idDocument || false}
                            onChange={(e) =>
                              handleFieldChange("checklist", {
                                ...editedData.checklist,
                                idDocument: e.target.checked,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          List of all residents on premises (ages must be
                          specified, and copies of identity documents attached).
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Checkbox
                            checked={
                              editedData.checklist?.residentsList || false
                            }
                            onChange={(e) =>
                              handleFieldChange("checklist", {
                                ...editedData.checklist,
                                residentsList: e.target.checked,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Proof of pension/grants.
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Checkbox
                            checked={
                              editedData.checklist?.pensionProof || false
                            }
                            onChange={(e) =>
                              handleFieldChange("checklist", {
                                ...editedData.checklist,
                                pensionProof: e.target.checked,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Proof of unemployment - Department of Manpower and/or
                          unemployment insurance certificate, original letter of
                          religious leader to confirm unemployment, and sworn
                          affidavit by applicant.
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Checkbox
                            checked={
                              editedData.checklist?.unemploymentProof || false
                            }
                            onChange={(e) =>
                              handleFieldChange("checklist", {
                                ...editedData.checklist,
                                unemploymentProof: e.target.checked,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Birth certificates/ID documents of dependent children
                          or a court order that confirms guardianship.
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Checkbox
                            checked={
                              editedData.checklist?.birthCertificates || false
                            }
                            onChange={(e) =>
                              handleFieldChange("checklist", {
                                ...editedData.checklist,
                                birthCertificates: e.target.checked,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          If owner of motor vehicle, full particulars as well as
                          means of finance.
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Checkbox
                            checked={
                              editedData.checklist?.motorVehicleOwner || false
                            }
                            onChange={(e) =>
                              handleFieldChange("checklist", {
                                ...editedData.checklist,
                                motorVehicleOwner: e.target.checked,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Latest municipal rates and services account.
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Checkbox
                            checked={
                              editedData.checklist?.municipalRates || false
                            }
                            onChange={(e) =>
                              handleFieldChange("checklist", {
                                ...editedData.checklist,
                                municipalRates: e.target.checked,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Copy of prepaid electricity slip.
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Checkbox
                            checked={
                              editedData.checklist?.prepaidElectricitySlip ||
                              false
                            }
                            onChange={(e) =>
                              handleFieldChange("checklist", {
                                ...editedData.checklist,
                                prepaidElectricitySlip: e.target.checked,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Does the applicant own or rent other properties?
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          <Checkbox
                            checked={
                              editedData.checklist?.otherProperties || false
                            }
                            onChange={(e) =>
                              handleFieldChange("checklist", {
                                ...editedData.checklist,
                                otherProperties: e.target.checked,
                              })
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  {/* Additional Information */}
                  {editedData.checklist?.otherProperties && (
                    <div>
                      <Text strong>Specify details:</Text>
                      <Input.TextArea
                        value={editedData.checklist?.otherPropertyDetails || ""}
                        onChange={(e) =>
                          handleFieldChange("checklist", {
                            ...editedData.checklist,
                            otherPropertyDetails: e.target.value,
                          })
                        }
                        placeholder="Enter details of other properties"
                        style={{ width: "100%", marginTop: "8px" }}
                        rows={3}
                      />
                    </div>
                  )}

                  <Divider />

                  {/* Approval Section */}
                  <div>
                    <Text strong>Approval Status:</Text>
                    <Radio.Group
                      value={editedData.checklist?.approvalStatus || "Pending"}
                      onChange={(e) =>
                        handleFieldChange("checklist", {
                          ...editedData.checklist,
                          approvalStatus: e.target.value,
                        })
                      }
                      style={{ marginLeft: "16px" }}
                    >
                      <Radio value="APPROVED">Approved</Radio>
                      <Radio value="DECLINED">Declined</Radio>
                      <Radio value="PENDING">Pending</Radio>
                    </Radio.Group>

                    {editedData.checklist?.approvalStatus === "Declined" && (
                      <div style={{ marginTop: "16px" }}>
                        <Text strong>Reason for Decline:</Text>
                        <Input.TextArea
                          value={editedData.checklist?.declineReason || ""}
                          onChange={(e) =>
                            handleFieldChange("checklist", {
                              ...editedData.checklist,
                              declineReason: e.target.value,
                            })
                          }
                          placeholder="Specify reason for declining the application"
                          style={{ width: "100%", marginTop: "8px" }}
                          rows={3}
                        />
                      </div>
                    )}
                  </div>

                  <div style={{ marginTop: "16px" }}>
                    <Text strong>Date of Approval/Decline:</Text>
                    <DatePicker
                      value={
                        editedData.checklist?.approvalDate
                          ? moment.utc(editedData.checklist?.approvalDate)
                          : null
                      }
                      onChange={(date) =>
                        handleFieldChange("checklist", {
                          ...editedData.checklist,
                          approvalDate: date ? date.format("YYYY-MM-DD") : null,
                        })
                      }
                      style={{ width: "100%", marginTop: "8px" }}
                    />
                  </div>

                  <div style={{ marginTop: "16px" }}>
                    <Text strong>Name of Official:</Text>
                    <Input
                      value={editedData.checklist?.officialName || ""}
                      onChange={(e) =>
                        handleFieldChange("checklist", {
                          ...editedData.checklist,
                          officialName: e.target.value,
                        })
                      }
                      placeholder="Enter name of approving/declining official"
                      style={{ width: "100%", marginTop: "8px" }}
                    />
                  </div>
                </div>
              </Panel>


              {/* Address Information Section */}
              <Panel header="Address Information" key="addressInfo">
                {/* Address1 Information */}
                <Divider orientation="left">Address 1</Divider>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Address Line 1:</Text>
                  <Input
                    value={
                      editedData.Address1_Line1 || personData.Address1_Line1
                    }
                    onChange={(e) =>
                      handleFieldChange("Address1_Line1", e.target.value)
                    }
                    placeholder="Enter Address Line 1"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                {/* Additional address fields */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Address Type:</Text>
                  <Select
                    value={
                      editedData.Address1_TypeDescription ||
                      personData.Address1_TypeDescription
                    }
                    onChange={(value) =>
                      handleFieldChange("Address1_TypeDescription", value)
                    }
                    style={{ width: "100%", marginTop: 8 }}
                  >
                    <Option value="Residential">Residential</Option>
                    <Option value="Postal">Postal</Option>
                  </Select>
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Suburb:</Text>
                  <Input
                    value={
                      editedData.Address1_Line2 || personData.Address1_Line2
                    }
                    onChange={(e) =>
                      handleFieldChange("Address1_Line2", e.target.value)
                    }
                    placeholder="Enter Suburb"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>City:</Text>
                  <Input
                    value={
                      editedData.Address1_Line3 || personData.Address1_Line3
                    }
                    onChange={(e) =>
                      handleFieldChange("Address1_Line3", e.target.value)
                    }
                    placeholder="Enter City"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Postal Code:</Text>
                  <Input
                    value={
                      editedData.Address1_PostalCode ||
                      personData.Address1_PostalCode
                    }
                    onChange={(e) =>
                      handleFieldChange("Address1_PostalCode", e.target.value)
                    }
                    placeholder="Enter Postal Code"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>

                {/* Address2 Information */}
                <Divider orientation="left">Address 2</Divider>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Address Line 1:</Text>
                  <Input
                    value={
                      editedData.Address2_Line1 || personData.Address2_Line1
                    }
                    onChange={(e) =>
                      handleFieldChange("Address2_Line1", e.target.value)
                    }
                    placeholder="Enter Address Line 1"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                {/* Additional address fields */}
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Address Type:</Text>
                  <Select
                    value={
                      editedData.Address2_TypeDescription ||
                      personData.Address2_TypeDescription
                    }
                    onChange={(value) =>
                      handleFieldChange("Address2_TypeDescription", value)
                    }
                    style={{ width: "100%", marginTop: 8 }}
                  >
                    <Option value="Residential">Residential</Option>
                    <Option value="Postal">Postal</Option>
                  </Select>
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Suburb:</Text>
                  <Input
                    value={
                      editedData.Address2_Line2 || personData.Address2_Line2
                    }
                    onChange={(e) =>
                      handleFieldChange("Address2_Line2", e.target.value)
                    }
                    placeholder="Enter Suburb"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>City:</Text>
                  <Input
                    value={
                      editedData.Address2_Line3 || personData.Address2_Line3
                    }
                    onChange={(e) =>
                      handleFieldChange("Address2_Line3", e.target.value)
                    }
                    placeholder="Enter City"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
                <div style={{ marginBottom: 8 }}>
                  <Text strong>Postal Code:</Text>
                  <Input
                    value={
                      editedData.Address2_PostalCode ||
                      personData.Address2_PostalCode
                    }
                    onChange={(e) =>
                      handleFieldChange("Address2_PostalCode", e.target.value)
                    }
                    placeholder="Enter Postal Code"
                    style={{ width: "100%", marginTop: 8 }}
                  />
                </div>
              </Panel>

              {/* Employment Information Section */}
              <Panel header="Employment Information" key="employmentInfo">
                {employmentDetails.map((emp, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: 16,
                      borderBottom: "1px solid #f0f0f0",
                      paddingBottom: 16,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text strong>Employment {index + 1}:</Text>
                      {index > 0 && (
                        <Button
                          type="danger"
                          icon={<MinusCircleOutlined />}
                          onClick={() => removeEmploymentEntry(index)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Employer Name:</Text>
                      <Input
                        value={emp.EmpName}
                        onChange={(e) =>
                          handleEmploymentChange(
                            index,
                            "EmpName",
                            e.target.value
                          )
                        }
                        placeholder="Enter Employer Name"
                        style={{ width: "100%", marginTop: 8 }}
                      />
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Position:</Text>
                      <Input
                        value={emp.EmpPosition}
                        onChange={(e) =>
                          handleEmploymentChange(
                            index,
                            "EmpPosition",
                            e.target.value
                          )
                        }
                        placeholder="Enter Position"
                        style={{ width: "100%", marginTop: 8 }}
                      />
                    </div>
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={addEmploymentEntry}
                  style={{ width: "100%" }}
                >
                  <PlusOutlined /> Add Employment
                </Button>
              </Panel>

              {/* Contact Information Section */}
              <Panel header="Contact Information" key="contactInfo">
                {contactDetails.map((contact, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: 16,
                      borderBottom: "1px solid #f0f0f0",
                      paddingBottom: 16,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text strong>Contact {index + 1}:</Text>
                      {index > 0 && (
                        <Button
                          type="danger"
                          icon={<MinusCircleOutlined />}
                          onClick={() => removeContactEntry(index)}
                        >
                          Remove
                        </Button>
                      )}
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Contact Number:</Text>
                      <Input
                        value={contact.Number}
                        onChange={(e) =>
                          handleContactChange(index, "Number", e.target.value)
                        }
                        placeholder="Enter Contact Number"
                        style={{ width: "100%", marginTop: 8 }}
                      />
                    </div>
                    <div style={{ marginBottom: 8 }}>
                      <Text strong>Contact Type:</Text>
                      <Select
                        value={contact.TypeDescription}
                        onChange={(value) =>
                          handleContactChange(index, "TypeDescription", value)
                        }
                        style={{ width: "100%", marginTop: 8 }}
                        placeholder="Select Contact Type"
                      >
                        <Option value="Mobile">Mobile</Option>
                        <Option value="Home">Home</Option>
                        <Option value="Work">Work</Option>
                      </Select>
                    </div>
                  </div>
                ))}
                <Button
                  type="dashed"
                  onClick={addContactEntry}
                  style={{ width: "100%" }}
                >
                  <PlusOutlined /> Add Contact
                </Button>
              </Panel>

              <Panel header="Supporting Documents" key="supportingDocuments">
                {existingDocuments.length > 0 ? (
                  <div style={{ overflowX: "auto" }}>
                    <Table
                      columns={[
                        {
                          title: "Document Type",
                          dataIndex: "documentType",
                          key: "documentType",
                          width: "70%", // Adjust width to take up more space
                        },
                        {
                          title: "Action",
                          key: "action",
                          render: (text, record) => (
                            <div style={{ whiteSpace: "nowrap" }}>
                              {record.url && (
                                <Button
                                  type="link"
                                  href={record.url}
                                  target="_blank"
                                >
                                  View
                                </Button>
                              )}
                            </div>
                          ),
                          width: "30%", // Adjust width as needed
                        },
                      ]}
                      dataSource={existingDocuments}
                      pagination={false}
                      rowKey="key"
                      size="small" // Makes the table more compact
                      style={{ marginBottom: 16 }}
                    />
                  </div>
                ) : (
                  <p>No supporting documents available.</p>
                )}

                {/* Upload functionality */}
                <div style={{ marginTop: 16 }}>
                  <Upload
                    accept="image/*,application/pdf"
                    customRequest={({ file, onSuccess }) => {
                      setTimeout(() => {
                        onSuccess("ok");
                      }, 0);
                    }}
                    onChange={handleFileChange}
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>Upload Document</Button>
                  </Upload>
                  <Divider />
                  {uploadedDocuments.map((doc, index) => (
                    <div key={index}>
                      <Text strong>Document Type:</Text>
                      <Select
                        value={doc.documentType || undefined}
                        onChange={(value) =>
                          handleDocumentTypeChange(index, value)
                        }
                        placeholder="Select Document Type"
                        style={{ width: "100%", marginTop: 8 }}
                      >
                        {documentTypes.map((type) => (
                          <Option key={type} value={type}>
                            {type}
                          </Option>
                        ))}
                      </Select>
                      <Divider />
                    </div>
                  ))}
                </div>
              </Panel>

              {/* Signature Section */}
              <Panel header="Signatures" key="signatures">
                <Collapse
                  defaultActiveKey={["indigentSignature", "officerSignature"]}
                >
                  <Panel
                    header="Indigent Signature"
                    key="indigentSignature"
                    style={{ padding: "0 40px" }}
                  >
                    <div
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        maxWidth: "400px",
                        margin: "0 auto",
                      }}
                    >
                      <Text strong>Signature:</Text>
                      <SignatureCanvas
                        ref={indigentSignaturePadRef}
                        penColor="black"
                        canvasProps={{
                          width: 350,
                          height: 200,
                          className: "signatureCanvas",
                        }}
                        style={{
                          border: "1px solid #000",
                          width: "100%",
                          marginTop: 8,
                        }}
                      />
                      <Button
                        onClick={() =>
                          handleClearSignature(indigentSignaturePadRef)
                        }
                        style={{
                          backgroundColor: "#28a745",
                          borderColor: "#28a745",
                          color: "#ffffff",
                          marginTop: 8,
                          width: "100px",
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                  </Panel>

                  <Panel
                    header="Ward Councillor Signature"
                    key="officerSignature"
                    style={{ padding: "0 40px" }}
                  >
                    <div
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        maxWidth: "400px",
                        margin: "0 auto",
                      }}
                    >
                      <Text strong>Signature:</Text>
                      <SignatureCanvas
                        ref={officerSignaturePadRef}
                        penColor="black"
                        canvasProps={{
                          width: 350,
                          height: 200,
                          className: "signatureCanvas",
                        }}
                        style={{
                          border: "1px solid #000",
                          width: "100%",
                          marginTop: 8,
                        }}
                      />
                      <Button
                        onClick={() =>
                          handleClearSignature(officerSignaturePadRef)
                        }
                        style={{
                          backgroundColor: "#28a745",
                          borderColor: "#28a745",
                          color: "#ffffff",
                          marginTop: 8,
                          width: "100px",
                        }}
                      >
                        Clear
                      </Button>
                    </div>
                  </Panel>
                </Collapse>
              </Panel>
            </Collapse>
          </div>
        )
      )}
    </Modal>
  );
}
