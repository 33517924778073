import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import { Modal, Button } from "antd";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [showInactivityPrompt, setShowInactivityPrompt] = useState(false);

  const inactivityTimeoutRef = useRef(null); // Ref for main inactivity timer
  const promptTimeoutRef = useRef(null); // Ref for prompt timeout

  const startInactivityTimer = () => {
    // Clear existing timers
    clearTimeout(inactivityTimeoutRef.current);
    clearTimeout(promptTimeoutRef.current);
    // Set inactivity timer
    inactivityTimeoutRef.current = setTimeout(() => {
      handleInactivity();
    }, 3600000); // 1 hour (3600000 milliseconds)
  };

  const handleInactivity = () => {
    if (!isAuthenticated || showInactivityPrompt) {
      return;
    }
;
    setShowInactivityPrompt(true);

    // Start a timer to log out after 10 seconds if no response
    promptTimeoutRef.current = setTimeout(() => {
      if (isAuthenticated) {
        logout();
      }
    }, 10000); // 10 seconds to respond
  };

  const respondToInactivity = () => {
    if (!showInactivityPrompt) {
      return;
    }

    clearTimeout(promptTimeoutRef.current); // Clear logout timer
    setShowInactivityPrompt(false); // Hide modal
    startInactivityTimer(); // Restart inactivity timer
  };

  const login = (userData) => {
    setIsAuthenticated(true);
    setUser(userData);
    startInactivityTimer(); // Start inactivity timer
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    setShowInactivityPrompt(false); // Hide modal
    clearTimeout(inactivityTimeoutRef.current); // Clear main timer
    clearTimeout(promptTimeoutRef.current); // Clear prompt timer
  };

  useEffect(() => {
    // Attach event listeners for user activity
    const resetTimer = () => {
      if (isAuthenticated) {
        startInactivityTimer();
      }
    };

    window.addEventListener("mousemove", resetTimer);
    window.addEventListener("keypress", resetTimer);

    return () => {
      // Cleanup event listeners and timers;
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keypress", resetTimer);

      clearTimeout(inactivityTimeoutRef.current);
      clearTimeout(promptTimeoutRef.current);
    };
  }, [isAuthenticated]);

  useEffect(() => {
    // Start inactivity monitoring upon login
    if (isAuthenticated) {
      startInactivityTimer();
    }
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, respondToInactivity }}>
      {children}
      {isAuthenticated && (
        <Modal
          title="Session Timeout Warning"
          visible={showInactivityPrompt}
          onCancel={logout} // Log out if modal is closed manually
          footer={[
            <Button key="logout" danger onClick={logout}>
              Logout
            </Button>,
            <Button key="stay" type="primary" onClick={respondToInactivity}>
              Stay Logged In
            </Button>,
          ]}
        >
          <p>You have been inactive for a while. Do you want to stay logged in?</p>
        </Modal>
      )}
    </AuthContext.Provider>
  );
};