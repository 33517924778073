import React from "react";
import { Layout, Row, Col } from "antd";
import { useAuth } from "../../AuthContext";

const { Footer } = Layout;

export default function AntdFooter() {
  const { user, logout } = useAuth();

  return (
    <Footer style={{ backgroundColor: "#fafafa" }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            © Verified by 
            <a
              href="https://dytelligence.co.za/"
              className="font-weight-bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              Dytelligence IT Solutions
            </a>
            &nbsp;|&nbsp;
            <a
              href="https://monitor.mampudilabs.com/"
              onClick={() => {
                logout();
                console.log("Logging out...");
              }}
            >
              Log Out <p>{user?.username || "Guest"}!</p>
            </a>
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>
              {[].map((item, index) => (
                <li className="nav-item" key={index}>
                  <a
                    href="#pablo"
                    className="nav-link text-muted"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </Footer>
  );
}