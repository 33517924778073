import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { dashboard } from "utils/SideNavData";
import MenuItem from "utils/SideNavData";
import { Button } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import userManual from "../../assets/docs/User Manual.pdf";

export default function SideNav({ color }) {
  // Sidebar starts open by default
  const [isOpen, setIsOpen] = useState(true);
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  const toggleSidebar = () => setIsOpen(!isOpen);

  const sidebarStyles = {
    container: {
      width: isOpen ? "250px" : "0",
      height: "100%",
      backgroundColor: "#2d3e50",
      color: "white",
      position: "fixed",
      top: 0,
      left: 0,
      overflowX: "hidden",
      transition: "width 0.3s ease",
      padding: isOpen ? "20px" : "0",
      boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
      zIndex: 1000,
    },
    brand: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "30px", // Increased spacing below brand section
    },
    logo: {
      width: "80px",
      marginBottom: "10px",
    },
    hr: {
      borderColor: "rgba(255, 255, 255, 0.2)",
      margin: "20px 0",
    },
    footerBox: {
      marginTop: "auto",
      padding: "15px",
      borderRadius: "8px",
      textAlign: "center",
      backgroundColor: "#1e2a38",
      marginBottom: "20px", // Space below footer section
    },
    footerText: {
      color: "#b0bec5",
    },
    button: {
      width: "100%",
      marginTop: "10px",
    },
    menuItem: {
      padding: "10px 15px",
      margin: "15px 0", // Increased vertical spacing between menu items
      display: "flex",
      alignItems: "center",
      borderRadius: "5px",
      transition: "background-color 0.3s",
      cursor: "pointer",
    },
    menuItemSpecialSpacing: {
      marginBottom: "30px", // Custom spacing for specific menu items
    },
    closeButton: {
      position: "absolute",
      top: "15px",
      right: "15px",
      color: "white",
      fontSize: "20px",
      cursor: "pointer",
      zIndex: 1001,
    },
    toggleMenu: {
      position: "fixed",
      top: "15px",
      left: "15px",
      zIndex: 1002,
      color: "#ffffff", // White icon color
      backgroundColor: "#28a745", // Green background
      padding: "6px", // Moderate padding for balanced look
      borderRadius: "8px", // Slightly rounded corners
      fontSize: "20px", // Subtle increase in icon size for visibility
      boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)", // Subtle shadow for depth
      display: isOpen ? "none" : "inline-block",
      cursor: "pointer",
    },
    overlay: {
      display: isOpen ? "block" : "none",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 999,
    },
  };

  return (
    <>
      {/* Overlay for dimming background */}
      <div style={sidebarStyles.overlay} onClick={toggleSidebar}></div>

      {/* Toggle Button for Small Screens */}
      <Button
        icon={<MenuOutlined style={{ fontSize: "20px" }} />}
        onClick={toggleSidebar}
        style={sidebarStyles.toggleMenu}
      />

      <div style={sidebarStyles.container}>
        {/* Close Icon for Sidebar */}
        <CloseOutlined onClick={toggleSidebar} style={sidebarStyles.closeButton} />

        <div style={sidebarStyles.brand}>
          <img src={logo} alt="Logo" style={sidebarStyles.logo} />
          <span>Verify Matlosana Indigents Applicants</span>
        </div>
        <hr style={sidebarStyles.hr} />

        {/* Menu Items with Custom Spacing */}
        <div style={{ ...sidebarStyles.menuItem, ...sidebarStyles.menuItemSpecialSpacing }}>
          <MenuItem pathname={pathname} color={color} />
        </div>

        <div style={sidebarStyles.footerBox}>
          <span className="icon" style={{ color: "red" }}>
            {dashboard(color)}
          </span>
          <h6>Need Help ?</h6>
          <p style={sidebarStyles.footerText}>Please check our docs</p>
          <Button
  type="primary"
  style={sidebarStyles.button}
  onClick={() => window.open(userManual, "_blank")}
>
  DOCUMENTATION
</Button>
        </div>
      </div>
    </>
  );
}