import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Layout,
  Button,
  Typography,
  Form,
  Input,
  Col,
  Row,
  Switch,
  message,
  Modal,
} from "antd";
import signinbg from "../assets/images/img-signin.jpg";
import { Header, Footer } from "components/Layout";
import { API_CONFIG } from "./config";
import { useAuth } from "../AuthContext";

const { Content } = Layout;
const { Title } = Typography;

const SignIn = () => {
  const navigate = useNavigate();
  const { login } = useAuth();

  // State for modal and new password
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [currentUsername, setCurrentUsername] = useState("");

  // Strong password rules
  const passwordRules = [
    { pattern: /.{8,}/, message: "Password must be at least 8 characters long." },
    { pattern: /[A-Z]/, message: "Password must include at least one uppercase letter." },
    { pattern: /[a-z]/, message: "Password must include at least one lowercase letter." },
    { pattern: /[0-9]/, message: "Password must include at least one number." },
    { pattern: /[@$!%*?&#]/, message: "Password must include at least one special character." },
  ];

  const validatePassword = (password) => {
    for (const rule of passwordRules) {
      if (!rule.pattern.test(password)) {
        return rule.message;
      }
    }
    return null; // Valid password
  };

  const handlePasswordChange = async () => {
    const validationError = validatePassword(newPassword);
    if (validationError) {
      message.error(validationError);
      return;
    }

    try {
      const response = await fetch(API_CONFIG.changePasswordUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": API_CONFIG.apiKey,
        },
        body: JSON.stringify({
          username: currentUsername,
          newPassword,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to update the password.");
      }

      message.success("Password changed successfully. Please log in again.");
      setIsModalVisible(false); // Close the modal
    } catch (error) {
      message.error(error.message || "Failed to update the password. Please try again.");
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await fetch(API_CONFIG.loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": API_CONFIG.apiKey,
        },
        body: JSON.stringify({
          username: values.username,
          password: values.password,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.ErrorCode) {
          switch (data.ErrorCode) {
            case "INVALID_CREDENTIALS":
              throw new Error("Invalid username or password. Please try again.");
            case "ACCOUNT_INACTIVE":
              throw new Error("Your account is inactive. Contact support.");
            case "ACCOUNT_LOCKED":
              throw new Error("Your account is locked. Please try again later or contact support.");
            case "PASSWORD_CHANGE_REQUIRED":
              message.info("You need to change your password before proceeding.");
              setCurrentUsername(values.username); // Save the username for password change
              setIsModalVisible(true); // Show the password change modal
              return;
            default:
              throw new Error("An unknown error occurred. Please try again.");
          }
        } else {
          throw new Error(data.message || "Login failed. Please try again.");
        }
      }

      message.success("Login successful!");
      console.log("Login Response: ", data);

      login(data); // Save user details to context
      navigate("/search"); // Redirect to a protected route
    } catch (error) {
      message.error(error.message || "Login failed. Please try again.");
      console.error("Login Error: ", error);
    }
  };

  return (
    <div className="layout-default layout-signin">
      <Header btn="primary" />
      <Content className="signin">
        <Row gutter={[24, 0]} justify="space-around">
          <Col xs={{ span: 24 }} lg={{ span: 6 }} md={{ span: 12 }}>
            <Title className="mb-15">Sign In</Title>
            <Title className="font-regular text-muted" level={5}>
              Enter your username and password to sign in
            </Title>
            <Form onFinish={onFinish} layout="vertical" className="row-col">
              <Form.Item
                className="username"
                label="Username"
                name="username"
                rules={[{ required: true, message: "Please input your username!" }]}
              >
                <Input placeholder="Username" />
              </Form.Item>
              <Form.Item
                className="password"
                label="Password"
                name="password"
                rules={[{ required: true, message: "Please input your password!" }]}
              >
                <Input placeholder="Password" type="password" />
              </Form.Item>
              <Form.Item name="remember" className="align-center" valuePropName="checked">
                <Switch defaultChecked />
                Remember me
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  SIGN IN
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col
            className="sign-img"
            style={{ padding: 12 }}
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <img src={signinbg} alt="" />
          </Col>
        </Row>
      </Content>
      <Footer />

      {/* Password Change Modal */}
      <Modal
        title="Change Password"
        visible={isModalVisible}
        onOk={handlePasswordChange}
        onCancel={() => setIsModalVisible(false)}
        okText="Change Password"
        cancelText="Cancel"
      >
        <p>Your password needs to be updated before proceeding. Please follow these rules:</p>
        <ul>
          {passwordRules.map((rule, index) => (
            <li key={index}>{rule.message}</li>
          ))}
        </ul>
        <Input.Password
          placeholder="Enter new password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default SignIn;