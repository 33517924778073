// Grid.js

import React, { useEffect, useState } from "react";
import {
  Spin,
  Row,
  Col,
  Card,
  Radio,
  Table,
  Input,
  Button,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { API_CONFIG } from "./config";
import { useAuth } from "../AuthContext";
import Popup from "./Popup"; // Import the Popup component

const { Search } = Input;

export default function Grid() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();
  const { user } = useAuth();

  const formData = {
    username: user.username,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(API_CONFIG.verificationUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": API_CONFIG.apiKey,
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setTableData(data.rtv);
        setFilteredData(data.rtv);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleGenderFilter = (gender) => {
    if (gender === "All") {
      setFilteredData(tableData);
    } else {
      setFilteredData(tableData.filter((item) => item.gender === gender));
    }
  };

  const handleSearch = (value) => {
    const searchValue = value ? value.toLowerCase() : "";
    const filtered = tableData.filter((item) => {
      const idNumber = item.idNumber ? item.idNumber.toLowerCase() : "";
      const fullName = `${item.firstNames ?? ""} ${item.surName ?? ""}`.toLowerCase();
      const wardNumber = item.wardNumber ? `ward ${item.wardNumber}`.toLowerCase() : "";
      return (
        idNumber.includes(searchValue) ||
        fullName.includes(searchValue) ||
        wardNumber.includes(searchValue)
      );
    });
    setFilteredData(filtered);
  };

  const handleRowClick = (record) => {
    setSelectedPerson(record);
    setIsModalVisible(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleAddClick = () => {
    navigate("/search");
  };

  const columns = [
    {
      title: "Ward",
      dataIndex: "wardNumber",
      key: "wardNumber",
      filters: Array.from({ length: 100 }, (_, i) => ({
        text: `Ward ${i + 1}`,
        value: i + 1,
      })), // Dynamically generate filters for wards 1–100
      onFilter: (value, record) => record.wardNumber === value,
      render: (wardNumber) => (wardNumber ? `Ward ${wardNumber}` : "N/A"),
      sorter: (a, b) => a.wardNumber - b.wardNumber,
    },
    {
      title: "ID Number",
      dataIndex: "idNumber",
      key: "idNumber",
    },
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
      render: (text, record) =>
        `${record.firstNames || ""} ${record.surName || ""}`.trim(),
      sorter: (a, b) => a.firstNames.localeCompare(b.firstNames),
    },
    {
      title: "DHA Status",
      dataIndex: "idnoMatchStatus",
      key: "idnoMatchStatus",
      filters: [
        { text: "Matched", value: "Matched" },
        { text: "Not Matched", value: "Not Matched" },
      ],
      onFilter: (value, record) => record.idnoMatchStatus === value,
      render: (idnoMatchStatus) => (
        <Tag color={idnoMatchStatus === "Matched" ? "green" : "red"}>
          {idnoMatchStatus}
        </Tag>
      ),
    },
    {
      title: "Deceased Status",
      dataIndex: "deceasedStatus",
      key: "deceasedStatus",
      filters: [
        { text: "Alive", value: "Alive" },
        { text: "Deceased", value: "Deceased" },
      ],
      onFilter: (value, record) => record.deceasedStatus === value,
      render: (deceasedStatus) => (
        <Tag color={deceasedStatus === "Alive" ? "green" : "red"}>
          {deceasedStatus}
        </Tag>
      ),
    },
    {
      title: "Marital Status",
      dataIndex: "maritalStatus",
      key: "maritalStatus",
      filters: [
        { text: "MARRIED", value: "MARRIED" },
        { text: "SINGLE", value: "SINGLE" },
        { text: "DIVORCED", value: "DIVORCED" },
        { text: "WIDOWED", value: "WIDOWED" },
      ],
      onFilter: (value, record) => record.maritalStatus === value,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      filters: [
        { text: "Male", value: "Male" },
        { text: "Female", value: "Female" },
      ],
      onFilter: (value, record) => record.gender === value,
    },
  ];

  const onChange = (e) => {
    handleGenderFilter(e.target.value);
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title="Indigent Data"
              extra={
                <Radio.Group onChange={onChange} defaultValue="All">
                  <Radio.Button value="All">All</Radio.Button>
                  <Radio.Button value="Male">Male</Radio.Button>
                  <Radio.Button value="Female">Female</Radio.Button>
                </Radio.Group>
              }
            >
              <Button
                type="primary"
                onClick={handleAddClick}
                style={{
                  backgroundColor: "#28a745",
                  borderColor: "#28a745",
                  marginRight: 8,
                }}
              >
                New Verification
              </Button>

              <Button
                type="primary"
                onClick={() => {}}
                style={{ marginRight: 8, visibility: "hidden" }}
              >
                Export to Excel
              </Button>

              <Search
                placeholder="Search by ID or Full Name"
                enterButton={<SearchOutlined />}
                onSearch={handleSearch}
                style={{ width: 300, marginLeft: 16 }}
              />
              <div className="table-responsive" style={{ marginTop: 16 }}>
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  loading={loading}
                  pagination={{ pageSize: 10 }}
                  rowKey="id"
                  onRow={(record) => ({
                    onClick: () => handleRowClick(record),
                  })}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      {/* Popup component */}
      {selectedPerson && (
        <Popup
          visible={isModalVisible}
          onClose={handleCloseModal}
          personData={selectedPerson}
        />
      )}
    </>
  );
}