import React from "react";
import { Layout, Button, Typography, Card, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { Header, Footer } from "components/Layout";
import { API_CONFIG } from "./config";
import { useAuth } from "../AuthContext";

const { Content } = Layout;
const { Title } = Typography;

const Search = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  // Function to validate South African ID numbers
  const validateSAIDNumber = (value) => {
    if (!value) {
      message.error("No ID number provided.");
      return false;
    }

    // Check if the ID number is 13 digits
    if (value.length !== 13 || isNaN(value)) {
      message.error("ID number must be a 13-digit numeric value.");
      return false;
    }

    // ID is valid
    console.log("ID number validation successful:", value);
    return true;
  };

  // Handler for form submission
  const onFinish = async (values) => {
    console.log("Form values: ", values);

    // Validate ID number
    if (!validateSAIDNumber(values.identitynumber)) {
      return;
    }

    // Prepare form data
    const formData = {
      identityNumber: values.identitynumber,
      username: user.username,
    };

    try {
      // Make the POST request to the real endpoint
      const response = await fetch(API_CONFIG.searchUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": API_CONFIG.apiKey, // Add the API key to the headers
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (response.status === 201) {
        // Handle successful verification
        message.success("Verification successful! Redirecting...");
        console.log("Server Response: ", result);

        // Delay navigation by 2 seconds
        setTimeout(() => {
          navigate("/tables");
        }, 2000);
      } else {
        // Handle other status codes as errors
        message.error(`Error: ${result.message || "Verification failed."}`);
      }
    } catch (error) {
      // Handle network or server errors
      message.error("There was an error processing your request.");
      console.error("API error: ", error);
    }
  };

  // Handler for failed form submission
  const onFinishFailed = (errInfo) => {
    console.log("Error: ", errInfo);
  };

  return (
    <div className="layout-default ant-layout layout-sign-up">
      <Header />
      <Content className="p-0">
        <div className="sign-up-header">
          <div className="content">
            <Title>Identity Verification</Title>
            <p className="text-lg">Gold-standard verification solutions</p>
          </div>
        </div>

        <Card
          className="card-signup header-solid h-full ant-card pt-0"
          title={<h5>Identity Verification</h5>}
          bordered={false}
        >
          <Form
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="row-col"
            name="basic"
          >
            <Form.Item
              name="identitynumber"
              rules={[
                {
                  required: true,
                  message: "Please input identity number!",
                },
              ]}
            >
              <Input placeholder="Identity Number" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                style={{
                  width: "100%",
                  backgroundColor: "#619b04",
                  borderColor: "#619b04",
                }}
                htmlType="submit"
              >
                Verify
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Content>
      <Footer />
    </div>
  );
};

export default Search;